import * as React from "react"
import Layout from "../components/Layouts/Layout/Layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <div>
    <Layout>
      <section>
        <div 
          className="container flex justify-content-center align-items-center flex-direction-column"
          style={{
            paddingBlock: "120px"
          }}>
          <h1>404</h1>
          <p>La page que vous avez demandé n'existe pas.</p>
        </div>
      </section>
    </Layout>
  </div>
)

export const Head = () => <Seo title="404" />

export default NotFoundPage
